.App {
  text-align: center;

  &__header {
    padding: 2rem;
  }

  &__title {
    font-size: 4rem;
    font-family: 'Titillium Web', sans-serif;
    @media (max-width: 768px) {
      font-size: 3.5rem;
    }
  }
}
