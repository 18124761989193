.recipe {
  width: 300px;
  height: 400px;
  background: #fff;
  text-transform: capitalize;
  -webkit-box-shadow: -2px 0px 10px -1px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: -2px 0px 10px -1px rgba(0, 0, 0, 0.6);
  box-shadow: -2px 0px 10px -1px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  position: relative;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.05);
  }

  &__img {
    width: 300px;
    height: 200px;
    border-radius: 5px 5px 0 0;
  }

  &__box {
    padding: 0 1.5rem;
  }

  &__title {
    margin: 0.5rem auto;
    text-align: left;
  }

  &__text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0.2rem;
    h3 {
      color: palevioletred;
    }
    h5 {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }

  &__button {
    max-width: 100%;
    box-sizing: border-box;
    display: inline-block;
    padding: 5px 90px;
    background: #000;
    color: #fff;
    border: 2px solid #000;
    border-radius: 2px;
    position: absolute;
    bottom: 18px;
    left: 22px;
    text-decoration: none;
    transition: 0.4s;
    &:hover {
      background: #fff;
      color: #000;
      border: 2px solid #000;
    }
  }
}
