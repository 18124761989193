.form {
  margin: 3rem 0;
  &__input {
    background: transparent;
    border: 0;
    border-bottom: solid 2px #2c3e50;
    margin-right: 10px;
    letter-spacing: 2px;
    width: 200px;
    padding: 0.4rem;
  }

  &__button {
    border: solid 2px #000;
    background: transparent;
    padding: 0.4rem 0.8rem;
    margin: 0 0.2rem;
    border-radius: 6px;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 400;
    margin-left: 0.7rem;
    transition: 0.4s;
    cursor: pointer;
    &:hover {
      background: #000;
      color: #fff;
    }
  }
}

.wrapper {
  max-width: 1000px;
  display: grid;
  grid-gap: 30px 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 3fr));
  grid-template-rows: repeat(auto-fit, 400px);
  justify-items: center;
  align-items: center;
  margin: 1rem auto;
}
