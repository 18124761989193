* {
  margin: 0;
  padding: 0;
}

html {
  @media (max-width: 900px) {
    font-size: 82.5%;
  }
}

body {
  background-image: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  font-family: 'Titillium Web', sans-serif;
}
